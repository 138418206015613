<template>
  <CHeader fixed colorScheme="dark" class="bg-primary" style="height:80px;">
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      v-c-emit-root-event:toggle-sidebar-mobile
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      v-c-emit-root-event:toggle-sidebar
    />
    <img
    style="max-width:45px !important;"
      :src="require('../assets/sosdenuncia/logo-comuna.svg')"
      class="navbar-brand"
      alt="región conectada"
    />
    <img
        style="max-width:140px !important;"
      :src="require('../assets/sosdenuncia/logo-loespejo2.png')"
      class="navbar-brand"
      alt="arica"
    />
    <CHeaderNav class="mr-auto">
      <CDropdown
        color="primary"
        toggler-text="Denuncias en Mapa"
        class="m-2 dropclusters-header"
      >
      <CDropdownItem @click="getDenunciasToMap()" >
          <div class="d-flex align-items-center justify-content-center">
						<img
							:src="require('../assets/sosdenuncia/logo-comuna - dark.svg')"
							style="object-fit: contain; max-width: 30px; max-height: 40px; border-radius: 8px;"
							class="mr-2"
							alt="Región Conectada"
						/>
						<p class="mb-0">Región Conectada (Todas)</p>
          </div>
        </CDropdownItem>
        <!-- <CDropdownItem @click="getDenunciasToMap()">
          <div class="d-flex align-items-center justify-content-center">
						<img
							:src="require('../assets/sosdenuncia/sosdenunciaslogo.jpg')"
							style="object-fit: contain; max-width: 40px; max-height: 40px; border-radius: 8px;"
							class="mr-2"
							alt="SOSclick"
						/>
						<p class="mb-0">SOSDenuncias (Todas)</p>
          </div>
        </CDropdownItem>
        <CDropdownItem @click="getDenunciasToMap(2)">
          <div class="d-flex align-items-center justify-content-center">
						<img
							:src="require('../assets/sosdenuncia/sosclickmujerlogo.jpg')"
							style="object-fit: contain; max-width: 40px; max-height: 40px; border-radius: 8px;"
							class="mr-2"
							alt="SOSclick"
						/>
						<p class="mb-0">SOSclick Mujer</p>
          </div>
        </CDropdownItem>
        <CDropdownItem @click="getDenunciasToMap(3)">
          <div class="d-flex align-items-center justify-content-center">
						<img
							:src="require('../assets/sosdenuncia/sosaguaslogo.jpg')"
							style="object-fit: contain; max-width: 40px; max-height: 40px; border-radius: 8px;"
							class="mr-2"
							alt="SOSclick"
						/>
						<p class="mb-0">SOSAguas</p>
          </div>
        </CDropdownItem> -->
        <!-- <CDropdownItem @click="getDenunciasToMap(1)"> -->
        <CDropdownItem
        class="dropbtnn dropdownn"
          v-for="item in complaintTypesHeader"
          :key="item.id"
          @click="
            () => {
                getComplaintByTypeToMap(item.id);
              }
            
          "
        >
          <div class="d-flex align-items-center justify-content-center">
            <img
              :src="item.marker_icon"
              style="
                object-fit: contain;
                max-width: 40px;
                max-height: 40px;
                border-radius: 8px;
              "
              class="mr-2"
              :alt="item.title"
            />
            <p class="mb-0">{{ item.title }}</p>


          <div class="dropdownn dropbtnn" v-if="item.subtypes.length > 0">
            <div class="dropdownn-content" style="overflow-y:scroll; min-height: 200px; max-height: 500px;">
            <div style="position:relative; overflow:hidden;">
            <div >
              <div
              style="padding: 6px;"
                v-for="subtype in item.subtypes"
                :key="subtype.id"
                @click="getComplaintByTypeToMap(subtype.id)"
              >
                <div class="d-flex align-items-center">
                  <img
                    :src="subtype.marker_icon"
                    style="
                      object-fit: contain;
                      max-width: 40px;
                      max-height: 40px;
                      border-radius: 8px;
                    "
                    class="mr-2"
                    :alt="subtype.title"
                  />
                  <p class="mb-0">{{ subtype.title }}</p>
                </div>
              </div>
            </div>
            </div>
            </div>
          </div>
          </div>
        </CDropdownItem>
        <CDropdownItem @click="clearDenunciasFromMap">
          <div class="d-flex align-items-center justify-content-center">
            <p class="mb-0">Limpiar Mapa</p>
          </div>
        </CDropdownItem>
      </CDropdown>

      

    </CHeaderNav>
    <CHeaderNav class="mr-auto"> </CHeaderNav>
    <CHeaderNav class="mr-4"> <OptionsDropdown /></CHeaderNav>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import AlertasDropdown from "./AlertasDropdown";
import OptionsDropdown from "./OptionsDropdown";
import {
  getComplaintsByCompany,
  getComplaintByType,
  getComplaintTypes,
} from "@/api/complaints";
import { mapState } from 'vuex';

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
    AlertasDropdown,
    OptionsDropdown,
  },
  data() {
    return {
      filter: {
        mujeres: true,
        comercio: true,
        hogar: true,
      },
    };
  },
  computed: {
    ...mapState(['complaintTypesHeader'])
  },
  mounted() {
    this.getComplaintTypes();
  },
  methods: {
    clearDenunciasFromMap() {
      this.$store.commit("setComplaintsInMap", null);
      this.$store.commit("setComplaintType", null);
    },
    async getComplaintTypes() {
      const complaints = await getComplaintTypes();
      if (complaints.code === 200) {
        this.$store.commit("setComplaintTypes", complaints.complaint_types);
      }
    },
    async getComplaintByTypeToMap(complaint_type_id) {
      const complaints = await getComplaintByType({ complaint_type_id });
      if (complaints.code === 200) {
        this.$store.commit("setComplaintsInMap", complaints.complaints);
        this.$store.commit("setComplaintType", complaint_type_id);
      }
    },

    async getDenunciasToMap(company_id = null) {
      const complaints = await getComplaintsByCompany({ company_id });
      if (complaints.code === 200) {
        this.$store.commit("setComplaintsInMap", complaints.complaints);
      }
    },
    showDropdown() {
      console.log("showDropdown");
    },
    tooglePanel() {
      this.$store.commit("setPanelShow", !this.$store.state.monitorPanelShow);
    },
    toggleMujeres() {
      this.filter.mujeres = !this.filter.mujeres;
      this.$events.fire("filterSegments", this.filter);
    },
    toggleComercio() {
      this.filter.comercio = !this.filter.comercio;
      this.$events.fire("filterSegments", this.filter);
    },
    toggleHogar() {
      this.filter.hogar = !this.filter.hogar;
      this.$events.fire("filterSegments", this.filter);
    },
    toggleBotones() {
      this.$store.commit("setShowBotones", !this.$store.state.showBotones);
    },
    toggleAlarmSound() {
      this.$store.commit("setAlarmSound", !this.$store.state.alarmSound);
    },
    toogleGrilla() {
      this.$store.commit("toogleGrilla", !this.$store.state.grillaMonitoreo);
    },
  },
};
</script>

<style scoped>
.navbar-brand{
  object-fit: contain;
  max-width: 75px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.dropbtnn {
  font-size: 16px;
  border: none;
  cursor: pointer;
  content: 'a';
}

.dropdownn {
  position: relative;
  display: inline-block;
  display: flex;
  justify-content: space-between;
}

.dropdownn-content {
  display: none;
  position: absolute;
  top:-30px;
  left: 50px;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdownn-content a {
  color: black;
  text-decoration: none;
  display: block;
}

.dropdownn-content a:hover {background-color: #f1f1f1}

.dropdownn:hover .dropdownn-content, .dropdownn-content:hover {
  display: block;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #afaeae #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #bebebe;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
</style>
