<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink style="color:#FFF;">
        Opciones <font-awesome-icon icon="chevron-down" style="margin-left: 5px;"/>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center text-white" color="primary">
      <strong>Opciones</strong>
    </CDropdownHeader>
    <CDropdownItem @click="toggleModalAudio">
      <font-awesome-icon icon="microphone" style="margin-right: 10px;"/> Ajustes de Audio
    </CDropdownItem>
    <CDropdownItem @click="toggleSearchUserModal">
      <font-awesome-icon icon="user" style="margin-right: 10px;"/> Buscar Usuarios
    </CDropdownItem>
    <div class="d-flex align-items-center item-drop-custom">
      <CSwitch
        class="mr-2"
        size="sm"
        color="primary"
        shape = "pill"
        @update:checked="toggleAlarmSound"
        :checked="$store.state.alarmSound"
      />
      <div>
        <p class="mb-0 mr-2 text-muted" style="font-size: 10px;"><strong>Sonido Alertas</strong></p>
      </div>
    </div>
    <div class="d-flex align-items-center item-drop-custom">
      <CSwitch
        class="mr-2"
        size="sm"
        color="primary"
        shape = "pill"
        :checked.sync="$store.state.showVideo"
      />
      <div>
        <p class="mb-0 mr-2 text-muted" style="font-size: 10px;"><strong>{{$store.state.showVideo ? 'Atención Primaria' : 'Seguridad'}}</strong></p>
        <p class="mb-0 mr-2 text-muted" style="font-size: 10px;">{{$store.state.showVideo ? 'Videoconferencia Automática' : 'Monitoreo Silencioso'}}</p>
      </div>
    </div>
    <div class="d-flex align-items-center item-drop-custom">
      <CSwitch
        class="mr-2"
        size="sm"
        color="primary"
        shape = "pill"
        @update:checked="toggleAutoVideoOnAlert"
        :checked.sync="$store.state.autoVideoOnAlert"
      />
      <div>
        <p class="mb-0 mr-2 text-muted" style="font-size: 10px;"><strong>Videollamada automática en Alerta</strong></p>
        <p class="mb-0 mr-2 text-muted" style="font-size: 10px;">{{$store.state.autoVideoOnAlert ? 'Activado' : 'Desactivado'}}</p>
      </div>
    </div>
    <div class="d-flex align-items-center item-drop-custom">
      <CSwitch
        class="mr-2"
        size="sm"
        color="primary"
        shape = "pill"
        @update:checked="toggleAutoVideoRecordOnAlert"
        :checked.sync="$store.state.autoVideoRecordOnAlert"
      />
      <div>
        <p class="mb-0 mr-2 text-muted" style="font-size: 10px;"><strong>Grabación automática en Alerta</strong></p>
        <p class="mb-0 mr-2 text-muted" style="font-size: 10px;">{{$store.state.autoVideoRecordOnAlert ? 'Activado' : 'Desactivado'}}</p>
      </div>
    </div>
  </CDropdown>
</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/database'

export default {
  name: 'OptionsDropdown',
  data () {
    return {
      alertas: [],
      animated: false
    }
  },
  mounted(){
  },
  methods: {
    toggleAlarmSound(){
      this.$store.commit('setAlarmSound', !this.$store.state.alarmSound)
    },
    toggleSearchUserModal(){      
      this.$store.commit('setShowUserModalSearch', !this.$store.state.showUserModalSearch)
    },
    toggleAutoVideoOnAlert(){
      this.$store.commit('setAutoVideoOnAlert', !this.$store.state.autoVideoOnAlert)
    },
    toggleAutoVideoRecordOnAlert(){
      this.$store.commit('setAutoVideoRecordOnAlert', !this.$store.state.autoVideoRecordOnAlert)
    },
    toggleModalAudio() {
      this.$store.commit('setModalAudioSettings', !this.$store.state.modalAudioSettings)
    }
  }
}
</script>
